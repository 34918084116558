<template>
    <div>
        <ts-panel>
            <ts-panel-wrapper>
                <ts-table
                    :columns="columns"
                    :records="resources"
                    :loading="loading"
                >
                    <template v-slot="{ record }">
                        <td>{{ record.request_type }}</td>
                        <td>{{ record.driver_name }}</td>
                        <td>{{ record.alert_time }}</td>
                        <td>{{ record.alert_expiry_time }}</td>
                        <td class="tw-text-center">
													<span class="tw-bg-green-100 tw-text-green-800 tw-text-xs tw-font-semibold tw-mr-2 tw-px-2.5 tw-py-0.5 tw-rounded"
														v-if="record.is_confirmed"
													>Yes</span>
													<span class="tw-bg-red-600 tw-text-white tw-text-xs tw-font-semibold tw-mr-2 tw-px-2.5 tw-py-0.5 tw-rounded"
														v-if="!record.is_confirmed"
													>No</span>
												</td>
                        <td class="tw-w-6 tw-text-center">
													{{ record.alert_counter }}
												</td>
                    </template>
                </ts-table>
                <div class="d-flex justify-content-end tw-p-4">
                    <ts-pagination
                        v-model="pagination"
                        @navigate="fetchData"
                    ></ts-pagination>
                </div>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
    name: "requestForDeliveryList",
    data() {
        return {
            loading: false
        };
    },
    computed: {
        ...mapState("agency/requestForDelivery", ["resources", "pagination"]),
        columns() {
            return [
                { name: this.$t("requestForDelivery.requestType"), sortKey: 'request_type' },
                { name: this.$t("requestForDelivery.driverName"), sortKey: 'driver_name' },
                { name: this.$t("requestForDelivery.alertTime"), sortKey: 'alert_time' },
                { name: this.$t("requestForDelivery.alertExpired"), sortKey: 'alert_expiry_time'},
                { name: this.$t("requestForDelivery.isConfirmed"), style: 'text-align:center' },
                { name: this.$t("requestForDelivery.alertCounter"), style: 'width:' }
            ];
        }
    },
    methods: {
        ...mapActions("agency/requestForDelivery", [
            "getFormViewData",
            "nearByDrive",
            "requestDelivery"
        ]),
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("agency/requestForDelivery/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$notify({ type: "error", text: error.message });
                });
        }
    }
};
</script>
