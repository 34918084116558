<template>
    <div>
        <ts-loading-banner :loading="loading">
            <div class="row">
                <div class="col-md-9" style="height: 75vh;">
                    <gmap-map
                        :center="center"
                        :zoom="zoom"
                        style="width: 100%; height: 100%"
                        ref="gmap"
                    >
                        <gmap-info-window
                            v-for="(win, index) in markers"
                            :key="index + Math.random()"
                            :options="{
                                maxWidth: 400,
                                pixelOffset: { width: 0, height: -35 }
                            }"
                            :position="{
                                lat: parseFloat(win.lat),
                                lng: parseFloat(win.lng)
                            }"
                            :opened="true"
                        >
                            <div>
                                <b>{{ win.driver_name }}</b>
                                <p
                                    class="tw-m-0 tw-text-white tw-px-1 tw-rounded-md"
                                    :class="{
                                        'tw-bg-red-500':
                                            win.driver_status === 'Busy',
                                        'tw-bg-green-500':
                                            win.driver_status === 'Free'
                                    }"
                                    style="width: fit-content;"
                                >
                                    <small>{{ win.driver_status }}</small>
                                </p>
                            </div>
                        </gmap-info-window>

                        <gmap-marker
                            v-for="(s, index) in markers"
                            :key="index"
                            :position="{
                                lat: parseFloat(s.lat),
                                lng: parseFloat(s.lng)
                            }"
                            :icon="{
                                url: icon,
                                scaledSize: { width: 35, height: 35 },
                                labelOrigin: {
                                    x: 30,
                                    y: -2
                                }
                            }"
                        />
                    </gmap-map>
                </div>
                <div class="col-md-3">
                    <div class="tw-flex tw-justify-between tw-items-center">
                        <div class="tw-flex tw-space-x-3">
                            <div
                                v-if="model.driver_id_list.length"
                                class="tw-flex tw-space-x-3"
                            >
                                <a-select
                                    v-model="model.agency_id"
                                    :options="agency"
                                    style="width:200px"
                                ></a-select>
                                <a-button
                                    type="primary"
                                    @click.prevent="request"
                                >
                                    {{ $t("requestForDelivery.request") }}
                                    <i class="fas fa-bullhorn tw-ml-3"></i>
                                </a-button>
                            </div>
                            <a-select
                                v-model="vehicle_type_id"
                                :options="vehicleTypeList"
                                style="width:200px"
                                v-if="!model.driver_id_list.length"
                            ></a-select>
                            <a-button
                                type="primary"
                                @click.prevent="getNearBy"
                                v-if="!model.driver_id_list.length"
                            >
                                <i class="fas fa-search-location tw-mr-3"></i>
                                {{ $t("requestForDelivery.nearByDriver") }}
                            </a-button>
                        </div>
                    </div>
                    <div
                        class="tw-space-y-2 tw-mt-3 tw-overflow-y-scroll"
                        style="height: 70vh;"
                    >
                        <div
                            class="tw-w-full tw-p-2 tw-border tw-rounded tw-cursor-pointer"
                            v-for="(d, index) in drivers"
                            :key="index"
                        >
                            <div class="tw-flex tw-justify-between">
                                <div
                                    class="tw-flex tw-space-x-2 tw-items-center"
																		@click.prevent="clickPaneTo(d)"
                                >
                                    <div>
                                        <i class="fas fa-user fa-2x"></i>
                                    </div>
                                    <div>
                                        <p
                                            class="tw-m-0 tw-text-lg tw-capitalize tw-text-blue-500"
                                        >
                                            {{ d.driver_name }}
                                            <span
                                                class="tw-text-white tw-px-2 tw-rounded tw-text-xs"
                                                :class="{
                                                    'tw-bg-green-500':
                                                        d.driver_status ==
                                                        'Free',
                                                    'tw-bg-red-500':
                                                        d.driver_status ==
                                                        'Busy'
                                                }"
                                                >{{ d.driver_status }}</span
                                            >
                                        </p>
                                        <p class="tw-m-0 tw-text-sm">
                                            {{ d.driver_code }}
                                        </p>
                                    </div>
                                </div>
                                <div class="tw-text-right">
                                    <ts-checkbox
                                        v-model="model.driver_id_list"
                                        :value="d.driver_id"
                                        style="padding-left: 8em; margin-right: -5px;"
                                    ></ts-checkbox>
                                    <p class="tw-m-0">
                                        {{
                                            differenceAsMinute(
                                                d.geo_modified_date
                                            )
                                        }}
                                    </p>
                                    <p class="tw-m-0">
                                        {{
                                            round(d.distance / 1000, 2) +
                                                " " +
                                                $t("requestForDelivery.km")
                                        }}
                                    </p>
                                </div>
                            </div>
                            <p class="tw-m-0">
                                {{ d.phone1 + " / " + d.phone2 }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </ts-loading-banner>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { round } from "lodash";
import moment from "moment";
import icon from "@/assets/people-location.svg";
export default {
    name: "requestForDelivery",
    data() {
        return {
            icon,
						zoom: 8,
            loading: false,
            vehicle_type_id: 1,
            drivers: [],
            center: { lng: 105.57359185298843, lat: 12.291513194851614 },
            model: {
                driver_id_list: [],
                agency_id: null
            }
        };
    },
    computed: {
        ...mapState("agency/requestForDelivery", ["formModels"]),
        agency() {
            if (!this.formModels.agency) return [];

            return this.formModels.agency.map(el => ({
                value: el.agency_id,
                label: el.agency_name
            }));
        },
        vehicleTypeList() {
            if (!this.formModels.vehicleType) return [];

            return this.formModels.vehicleType.map(el => ({
                value: el.vehicle_type_id,
                label: el.vehicle_types
            }));
        },
        isChecked() {
            if (!this.drivers.length) return false;
            return this.model.driver_id_list.length == this.drivers.length;
        },
        differenceAsMinute: () => dateString => {
            var now = moment();
            const duration = moment.duration(now.diff(moment(dateString)));
            return duration.humanize();
        },
        markers() {
            return this.drivers.filter(
                u => (u.lat !== null) & (u.lng !== null)
            );
        }
    },
    methods: {
        ...mapActions("agency/requestForDelivery", [
            "getFormViewData",
            "nearByDrive",
            "requestDelivery"
        ]),
        round,
        async fetchViewData() {
            this.loading = true;
            await this.getFormViewData({ param: {}, index: undefined });
            this.loading = false;
        },
        getNearBy() {
            this.loading = true;
            this.nearByDrive({
                vehicle_type_id: this.vehicle_type_id
            })
                .then(response => {
                    this.drivers = response.data;
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        request() {
            this.loading = true;
            this.requestDelivery(this.model)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        checkboxChange(e) {
            if (e) {
                this.drivers.forEach(el => {
                    this.model.driver_id_list.push(el.driver_id);
                });
            } else {
                this.model.driver_id_list = [];
            }
        },
        clickPaneTo(record) {
            if ((record.lat !== null) & (record.lng !== null)) {
                this.$refs.gmap.$mapPromise.then(map => {
                    map.panTo({
                        lat: parseFloat(record.lat),
                        lng: parseFloat(record.lng)
                    });
                    this.zoom = 20;
                });
            }
        }
    }
};
</script>
<style>
.gm-ui-hover-effect {
    opacity: 0.6;
    display: none !important;
}
</style>
